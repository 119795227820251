<template>
  <!-- eslint-disable vue/camelcase -->
  <div>
    <div class="row">
      <div class="col-12">
        <div class="row mb-md-2">
          <div class="col-sm-12 col-md-6">
            <div class="form-inline">
              <label>
                Show&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.per_page"
                  class="ml-1 mr-1"
                  size="sm"
                  :options="[10, 15, 25, 50, 100]"
                  @change="getReviews"
              ></b-form-select>
              <label class="ml-3">
                Review Filter:&nbsp;
              </label>
              <b-form-select
                  v-model="datatable.queries.review_rating"
                  class="ml-1 mr-1 status-filter"
                  size="sm"
                  :options="[{ value: 1, text: '1 Star' },{ value: 2, text: '2 Stars' },{ value: 3, text: '3 Stars' },{ value: 4, text: '4 Stars' },{ value: 5, text: '5 Stars' }, { value: 0, text: 'All' }]"
                  @change="getReviews"
              ></b-form-select>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div class="dataTables_filter text-md-right">
              <vue-json-to-csv :json-data="reviews" :csv-title="csvTitle()">
                <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
              </vue-json-to-csv>
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                    v-model="datatable.queries.search"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
              <button
                  class="ml-1 btn btn-dark-blue btn-sm"
                  @click="getReviews"
              >
                <i class="uil uil-search-alt"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="card rwg-app-panel">
          <div class="card-body">
            <template v-if="!loadingReviews">
              <div class="table-responsive mb-0">
                <b-col>
                  <h4 v-if="business">Reviews: {{business.business_name}}</h4>
                </b-col>
                <b-col>
                  <div id="rwg-review-header" class="rwg-review-header">
                    <div class="rwg-review-header-left">
                      <div class="rwg-posted-on-source"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 92" width="87" height="30">
                        <path fill="#EA4335" d="M115.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18C71.25 34.32 81.24 25 93.5 25s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44S80.99 39.2 80.99 47.18c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"></path>
                        <path fill="#FBBC05" d="M163.75 47.18c0 12.77-9.99 22.18-22.25 22.18s-22.25-9.41-22.25-22.18c0-12.85 9.99-22.18 22.25-22.18s22.25 9.32 22.25 22.18zm-9.74 0c0-7.98-5.79-13.44-12.51-13.44s-12.51 5.46-12.51 13.44c0 7.9 5.79 13.44 12.51 13.44s12.51-5.55 12.51-13.44z"></path>
                        <path fill="#4285F4" d="M209.75 26.34v39.82c0 16.38-9.66 23.07-21.08 23.07-10.75 0-17.22-7.19-19.66-13.07l8.48-3.53c1.51 3.61 5.21 7.87 11.17 7.87 7.31 0 11.84-4.51 11.84-13v-3.19h-.34c-2.18 2.69-6.38 5.04-11.68 5.04-11.09 0-21.25-9.66-21.25-22.09 0-12.52 10.16-22.26 21.25-22.26 5.29 0 9.49 2.35 11.68 4.96h.34v-3.61h9.25zm-8.56 20.92c0-7.81-5.21-13.52-11.84-13.52-6.72 0-12.35 5.71-12.35 13.52 0 7.73 5.63 13.36 12.35 13.36 6.63 0 11.84-5.63 11.84-13.36z"></path>
                        <path fill="#34A853" d="M225 3v65h-9.5V3h9.5z"></path>
                        <path fill="#EA4335" d="M262.02 54.48l7.56 5.04c-2.44 3.61-8.32 9.83-18.48 9.83-12.6 0-22.01-9.74-22.01-22.18 0-13.19 9.49-22.18 20.92-22.18 11.51 0 17.14 9.16 18.98 14.11l1.01 2.52-29.65 12.28c2.27 4.45 5.8 6.72 10.75 6.72 4.96 0 8.4-2.44 10.92-6.14zm-23.27-7.98l19.82-8.23c-1.09-2.77-4.37-4.7-8.23-4.7-4.95 0-11.84 4.37-11.59 12.93z"></path>
                        <path fill="#4285F4" d="M35.29 41.41V32H67c.31 1.64.47 3.58.47 5.68 0 7.06-1.93 15.79-8.15 22.01-6.05 6.3-13.78 9.66-24.02 9.66C16.32 69.35.36 53.89.36 34.91.36 15.93 16.32.47 35.3.47c10.5 0 17.98 4.12 23.6 9.49l-6.64 6.64c-4.03-3.78-9.49-6.72-16.97-6.72-13.86 0-24.7 11.17-24.7 25.03 0 13.86 10.84 25.03 24.7 25.03 8.99 0 14.11-3.61 17.39-6.89 2.66-2.66 4.41-6.46 5.1-11.65l-22.49.01z"></path>
                      </svg> <span class="source-rating-text">Rating</span></div>
                      <div v-if="meta" class="rwg-review-header-bottom">
                        <div class="rwg-review-avg-header">{{ reviewAvg }}</div>
                        <div class="rwg-review-rating">
                          <div id="rwg-floating-rating" class="rating-upper" :style="'width:' + starWidth + '%'"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                          <div class="rating-lower"> <span>★</span> <span>★</span> <span>★</span> <span>★</span> <span>★</span> </div>
                        </div>
                        <div class="rwg-review-total">{{reviewTotal}} reviews</div>
                      </div>
                    </div>
                  </div>
                </b-col>
                <b-table
                  :items="reviews"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  data-export-types="['csv']"
                  :no-local-sorting="true"
                  thead-class="d-none"
                  :borderless="true"
                  @sort-changed="sortChanged"
                >

                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(review)="data">
                    <Review :review="data.item" class="position-relative">
                      <div class="visibility-toggle">

                        <button v-if="data.item.visible" type="button" class="btn btn-success rounded-0" @click="toggleVisibility(data.item)">
                          <b-spinner v-if="visibilityWorking" small class="m-auto"></b-spinner>
                          <i v-else class="uil uil-eye" />
                          <span>Show</span>
                        </button>
                        <button v-else type="button" class="btn btn-danger rounded-0" @click="toggleVisibility(data.item)">
                          <b-spinner v-if="visibilityWorking" small class="m-auto"></b-spinner>
                          <i v-else class="uil uil-eye-slash" />
                          <span>Hide</span>
                        </button>
                      </div>
                    </Review>
                  </template>

                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalReviews }} reviews
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                        v-model="datatable.queries.page"
                        :total-rows="totalReviews"
                        :per-page="datatable.queries.per_page"
                        @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<style lang="scss" scoped>
  .visibility-toggle{
    position: absolute;
    top: 15px;
    right: 15px;
    button{
      padding: 2px 10px;
      i{
        display: block;
        font-size: 20px;
        line-height: 15px;
      }
      span{
        display: block;
        font-size: 10px;
      }
    }
  }
</style>
<script>
import VueJsonToCsv from 'vue-json-to-csv';
import Review from '@components/Common/Review';
/* import Vue from "vue"; */
export default {
  components:{
    VueJsonToCsv,
    Review
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'review', label: '', sortable: false },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
          review_rating: 0
        },
      },
      business:null,
      loadingReviews: false,
      loadingImpersonate: false,
      loadingRemove: null,
      createIsBusy:false,
      visibilityWorking:false
    }
  },

  computed: {
    reviews() {
      return this.$store.getters['reviews/all'] || []
    },

    totalReviews() {
      return this.$store.getters['reviews/total']
    },

    meta() {
      return this.$store.getters['reviews/meta']
    },
    reviewTotal(){
      return this.meta?.business_rating_count ?? this.meta.review_total ?? 0;
    },
    reviewAvg(){
      return this.meta.business_rating_avg ? this.meta.business_rating_avg.toFixed(1) : this.meta.average.toFixed(1);
    },
    starWidth(){
      const avg = this.meta.business_rating_avg ? this.meta.business_rating_avg : this.meta.average;
      return (avg / 5) * 100
    },

    reload() {
      return this.$store.getters['reviews/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

  },
  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.$store.dispatch('reviews/setCurrentPage', 'index')
  },

  mounted() {
    this.getReviews();
  },

  methods: {
    toggleVisibility(review){
      this.visibilityWorking = true;
      this.$store
          .dispatch('reviews/toggleVisibility', review)
          .then((res) => {
            this.visibilityWorking = false;
            review.visible = res.visible;
          })
          .catch(() => {
            this.visibilityWorking = false;
          })
    },
    csvTitle(){
      return 'review-widget-generator-reviews'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getReviews()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getReviews()
    },

    getReviews() {
      this.loadingReviews = true
      this.$store
        .dispatch('reviews/getAll', {
          queries: this.datatable.queries,
          page: 'index',
          businessId: this.$route.params.business
        })
        .then((res) => {
          this.business = res.business;
          this.loadingReviews = false
        })
        .catch(() => {
          this.loadingReviews = false
        })
    },
  },
}
</script>
